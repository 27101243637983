import { Helmet } from 'react-helmet-async';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Container, Slider, Typography, Button, 
    Alert,
    Snackbar,Fab, Chip, Avatar, Popover, CardHeader , Card,} from '@mui/material';
// components
import React, { useContext,useState, useEffect  } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';

import _ from 'lodash';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Searchbar from './Searchbar';
import Iconify from '../components/iconify';

import { fShortenNumber } from '../utils/formatNumber';
// sections
import {
    Upload
} from '../sections/@dashboard/app';
import { useChart } from '../components/chart';

// ----------------------------------------------------------------------

const extensionIcons = {
    png: { icon: 'file-image', color: '#e57373' },
    jpg: { icon: 'file-image', color: '#e57373' },
    jpeg: { icon: 'file-image', color: '#e57373' },
    gif: { icon: 'file-image', color: '#e57373' },
    bmp: { icon: 'file-image', color: '#e57373' },
    pdf: { icon: 'file-pdf', color: '#64b5f6' },
    docx: { icon: 'file-word', color: '#81c784' },
    ppt: { icon: 'file-powerpoint', color: '#ffab91' },
    pptx: { icon: 'file-powerpoint', color: '#ffab91' },
    xls: { icon: 'file-excel', color: '#4db6ac' },
    xlsx: { icon: 'file-excel', color: '#4db6ac' },
    mp3: { icon: 'file-music', color: '#9575cd' },
    wav: { icon: 'file-music', color: '#9575cd' },
    ogg: { icon: 'file-music', color: '#9575cd' },
    mp4: { icon: '', color: '#f06292' },
    avi: { icon: '', color: '#f06292' },
    mkv: { icon: '', color: '#f06292' },
    mov: { icon: '', color: '#f06292' },
    // Add more extensions and corresponding Iconify icons/colors as needed
  };
export default function Report() {
  const Fav = styled('fab')(({ theme }) => ({
    position: 'fixed',
    zIndex: 500,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }));

  const [user, setUser] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [reportInfo, setReportInfo] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [oldExams, setOldExams] = useState([]);
  const [aufwand, setAufwand] = useState(0);
  const [stdWeek, setStdWeek] = useState(0);
  const [minstdWeek, setMinStdWeek] = useState(0);
  const [maxstdWeek, setMaxStdWeek] = useState(0);
  const [mehrWertVorl, setMehrWertVorl] = useState(0);
  const [mehrWertUeb, setMehrWertUeb] = useState(0);
  const [uploadResult, setUploadResult] = useState(null);
  const [toUpload, setToUpload] = useState([]);

  const handleUploadResult = (status) => {
    setUploadResult(status);
  };

  const handleSnackbarClose = () => {
    setUploadResult(null);
  };
  const [name, setName] = useState(decodeURIComponent(window.location.hash.substring(1)));

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  async function postData(url, payload) {
    const token = localStorage.getItem('deltatoken');
    
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
  
      const success = response.ok;
      const data = await response.json();
      return { success, data };
    } catch (error) {
      return { success: false, data: "No internet connection or server unavailable." };
    }
  }

  useEffect(() => {  
    setIsMobile(window.innerWidth <= 768);
    const token = localStorage.getItem('deltatoken');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch('https://corpsdelta.study/api/user', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
      setUser(response.json().user);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  
  useEffect(() => {
    const fetchKeywordInfo = async () => {
    try {
        const responseData = await postData('https://corpsdelta.study/api/report', { input: name});
        const info = JSON.parse(responseData.data);
        console.log(info)
        setReportInfo(info.map((item) => ({ ...item, checked: true })));
        setAufwand(_.meanBy(info, 'aufwand'))
        setStdWeek(_.meanBy(info, 'wochenstunden'))
        console.log("min")
        console.log( Math.min(... info.map((item) => item.wochenstunden)))
        
        setMinStdWeek(Math.min(... info.map((item) => item.wochenstunden) ))
        setMaxStdWeek(Math.max(... info.map((item) => item.wochenstunden) ))
        setMehrWertVorl(_.meanBy(info, 'mehrwert_vorlesungen'))
        setMehrWertUeb(_.meanBy(info, 'mehrwert_uebungen'))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchKeywordInfo()
  }, [name]);

  useEffect(() => {
    const fetchKeywordInfo = async () => {
    try {
        const responseData = await postData('https://corpsdelta.study/api/get_documents', { input: name});
        const info = (responseData.data);
        console.log("learnDocs")
        console.log(info.learnDocs)
        setOldExams(info.oldExams);
        setDocuments(info.learnDocs);
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchKeywordInfo()
  }, [name, uploadResult]);


  
  const handleChipClick = (index) => {
    console.log(index)
    const updatedChipsData = [...reportInfo];
    updatedChipsData[index].checked = !updatedChipsData[index].checked;
    setReportInfo(updatedChipsData);
  };
  
  const onYearChange = (event) => {
    const t = 1-event.target.value;
    console.log(t);
  };
  return (
    <>
      <Helmet>
        <title> Delta Studies </title>
      </Helmet>
      
      <Fav>
        <a href={`/dashboard/newpage#${name}`}>
      <Fab color="secondary" aria-label="add">
          <Iconify  icon={'bi:plus'}  color="#fff" width={32} />
      </Fab></a>
      </Fav>
      <Grid container spacing={2}>    

      <Grid item xs={12} md={12} lg={12} >   
      <Card style={{background: "linear-gradient(135deg, #afafaf 0%,#490b0b 51%,#a8975a 100%)", textAlign: "center", color: "#fff"}}>
        
        <CardHeader title={name} subheader={`${reportInfo.length} Berichte`}  subheaderStyle={{ color: '#fff' }} style={{paddingBottom: '20px', color:'#fff'}}/>

      </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={12} >   
      <Card style={{padding: '10px'}}>
        <Slider defaultValue={1} min={0} step={1} max={3}  onChange={onYearChange} style={{display: "none"}}/>

        {reportInfo.map((item, index) => (
           <Chip  clickable   onClick={() => handleChipClick(index)}
           color={item.checked ? 'primary' : 'default'}
           label={`${item.name} (${item.sws}-${item.jahr})`} style={{marginRight: '10px', marginBottom: '5px', color: '#fff'}} />
        ))}
      </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={12} >   
      <Card style={{padding: '10px'}}>
      <CardHeader title={"Dokumente"}  />
      <div style={{padding: '23px'}}>
        {documents.map((item, index) => (
          <a href={`https://corpsdelta.study/api/documents/${name}/lern-dokumente/${item}`} target='_blank' rel="noreferrer"><Chip  clickable 
          avatar={
            extensionIcons[item.substring(item.lastIndexOf(".") + 1).toLowerCase()] ? (
              <Icon
                icon={`mdi:${extensionIcons[item.substring(item.lastIndexOf(".") + 1).toLowerCase()].icon}`}
                color={extensionIcons[item.substring(item.lastIndexOf(".") + 1).toLowerCase()].color}
              />
            ) : ( <Icon icon={`mdi:file`} color="default" />)
          }
           label={`${item}`} style={{marginRight: '10px', marginBottom: '5px'}} /></a> 
        ))}
        {documents.length === 0 && (<p style={{float:'left', color: '#555'}}>Keine Dokumente gefunden</p>)}</div>
        <CardHeader title={"Alt-Klausuren"} style={{width: "100%"}} />
        <div style={{padding: '23px'}}>
        {oldExams.map((item, index) => (
          <a href={`https://corpsdelta.study/api/documents/${name}/altklausuren/${item}`}target='_blank' rel="noreferrer"><Chip  clickable 
            
          avatar={
            extensionIcons[item.substring(item.lastIndexOf(".") + 1).toLowerCase()] ? (
              <Icon
                icon={`mdi:${extensionIcons[item.substring(item.lastIndexOf(".") + 1).toLowerCase()].icon}`}
                color={extensionIcons[item.substring(item.lastIndexOf(".") + 1).toLowerCase()].color}
              />
            ) : ( <Icon icon={`mdi:file`} color="default" />)
          }
           label={`${item}`} style={{marginRight: '10px', marginBottom: '5px'}} /></a> 
        ))}
        {oldExams.length === 0 && (<p style={{float:'left', color: '#555'}}>Keine Altklausuren gefunden</p>)}</div>
        <CardHeader title={"Upload"} style={{width: "100%"}} />
        <div style={{padding: '23px'}}>
                <Upload _examName={name}  setSelectedFiles={setToUpload} selectedFiles={toUpload} isMobile={isMobile}  handleUploadResult={handleUploadResult}/>
        </div>
      </Card>
      </Grid>

      
      <Grid item xs={12} md={12} lg={12} >   
      <Card style={{padding: '10px'}}>
        <CardHeader title={"Zum Fach"} style={{ paddingBottom: '20px'}} />
        <div style={{padding: '20px', paddingTop: '0px'}}>
        <Grid container spacing={2}>    
        {reportInfo.map((item, index) => (
            item.checked &&(
            <Grid item xs={6} md={6} lg={4} >  
            <Card style={{padding: '10px'}}>
            {item.studiengang && (<p><b>Studiengang </b>{item.studiengang}</p>)}
            {item.hilfreiche_veranstaltungen && (<p><b>Hilfreiche Veranstaltungen: </b>{item.hilfreiche_veranstaltungen}</p>)}
          {item.institut && (<p><b>Institut/Prof: </b>{item.institut}</p>)}
          {(item.cp > 0) && (<p><b>CP: </b>{item.cp}</p> )}   
            
           <p style={{textAlign: 'right', color:'#999'}}>{`${item.name} (${item.sws}-${item.jahr})`}</p>
           </Card>
            </Grid>)
        ))}
        </Grid>
        </div>
      </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12} >   
      <Card style={{padding: '10px'}}>
      <CardHeader title={"Im Semester"} style={{ paddingBottom: '20px'}} />
      <div style={{padding: '25px', width: '100%', marginTop: '-10px'}}>

      <table style={{ width: '100%'}}>
            <tbody>
           
            <tr style={{borderBottom: "1px solid #aaa", borderTop: "1px solid #aaa"}}>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" ,borderLeft: "1px solid #aaa" }}>Aufwand</td>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" }}><progress id="file" value={aufwand} max="100"> {aufwand}% </progress>
                {reportInfo.map((item) => (
                   (item.aufwand_tipps.length > 0 && (<p>{item.aufwand_tipps}<span style={{color:'#aaa'}}> ~{item.name}</span></p>))
                ))}
                </td>
            </tr>
            <tr style={{borderBottom: "1px solid #aaa"}}>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa",borderLeft: "1px solid #aaa"   }}>Mehrwert Vorlesungen</td>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" }}><progress style={{widh: '100%'}} id="file" value={mehrWertVorl} max="100"> {mehrWertVorl}% </progress>
                {reportInfo.map((item) => (
                    (item.vorlesungen_tipps.length > 0 && (<p>{item.vorlesungen_tipps}<span style={{color:'#aaa'}}> ~{item.name}</span></p>))
                ))}
                </td>
            </tr>
            <tr style={{borderBottom: "1px solid #aaa"}}>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" ,borderLeft: "1px solid #aaa"  }}>Mehrwert Übungen</td>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" }}><progress style={{widh: '100%'}} id="file" value={mehrWertUeb} max="100"> {mehrWertUeb}% </progress>
                {reportInfo.map((item) => (
                    (item.uebungen_tipps.length > 0 && (<p>{item.uebungen_tipps}<span style={{color:'#aaa'}}> ~{item.name}</span></p>))
                ))}
                </td>
            </tr>
            <tr>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa",borderLeft: "1px solid #aaa"   }}>Stunden/Woche</td>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" }}>{stdWeek.toFixed(2)} h (Min: {minstdWeek}h - Max: {maxstdWeek}h)</td>
            </tr>
            <tr>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa",borderLeft: "1px solid #aaa"   }}>Zulassung</td>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" }}>{`Ja: ${reportInfo.filter((item) => item.zulassung === 1).length}, Nein: ${reportInfo.filter((item) => item.zulassung === 0).length}`}</td>
            </tr>
            <tr style={{borderBottom: "1px solid #aaa"}}>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" ,borderLeft: "1px solid #aaa"  }}>Auswendig lernen</td>
                <td style={{  padding: '10px',textAlign: 'left',borderRight: "1px solid #aaa" }}>{`Ja: ${reportInfo.filter((item) => item.auswendig === 1).length}, Nein: ${reportInfo.filter((item) => item.auswendig === 0).length}`}</td>
            </tr>
            </tbody>
        </table>
        
     
        </div>
      </Card>
      </Grid>
      
      <Grid item xs={12} md={12} lg={12} >   
      <Card style={{padding: '10px'}}>
        <CardHeader title={"Lernzeit"} style={{ paddingBottom: '20px'}} />
        <div style={{padding: '20px', paddingTop: '0px'}}>
        <Grid container spacing={2}>    
        {reportInfo.map((item, index) => (
            item.checked &&(
            <Grid item xs={6} md={6} lg={4} >  
            <Card style={{padding: '10px'}}>
          <p>{`${item.anzahllerntage} Tage a ${item.stunden_lerntag}h = ${item.anzahllerntage*item.stunden_lerntag}h`}</p>    
          <p>{`${item.anzahl_altklausuren} Altklausur(en)`}</p>    
          <p> <b>{item.note === 5 && "Nicht Bestanden "}</b>{item.bewertung_lernzeit.length > 0 && `Einschätzung: ${item.bewertung_lernzeit}`}</p>    
           <p style={{textAlign: 'right', color:'#999'}}>{`${item.name} (${item.sws}-${item.jahr})`}</p>
           </Card>
            </Grid>)
        ))}
        </Grid>
        </div>
      </Card>
      </Grid>
      

      <Grid item xs={12} md={12} lg={12} >   
      <Card style={{padding: '10px'}}>
      <CardHeader title={"Klausur Rückblick"} style={{ paddingBottom: '20px'}} />
      <div style={{padding: '20px', paddingTop: '0px'}}>
            <Grid container spacing={2}>    
        {reportInfo.map((item, index) => (
           item.checked && item.tipps.length > 0 &&(
             <Grid item xs={12} md={6} lg={6} >  
          <Card style={{padding: '20px'}}>
          {item.klausur_rueckblick.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                    {line}
                    <br />
                    </React.Fragment>
                ))}
                <p style={{color: '#aaa'}}>{item.versuch}.Versuch, Note: {typeof item.note === 'number' && !Number.isNaN(item.note) ? item.note.toFixed(1) : item.note}, Einsicht: {item.einsicht ===1 ? "Ja" : "Nein"}</p>
            <p style={{textAlign: 'right', color:'#999'}}>{`${item.name} (${item.sws}-${item.jahr})`}</p>
            </Card>
            </Grid>)
        ))}
        </Grid>
        </div>
      </Card>
      </Grid>


      

      <Grid item xs={12} md={12} lg={12} >   
      <Card style={{padding: '10px'}}>
      <CardHeader title={"Tipps"} style={{ paddingBottom: '20px'}} />
      <div style={{padding: '20px', paddingTop: '0px'}}>
            <Grid container spacing={2}>    
        {reportInfo.map((item, index) => (
           item.checked && item.tipps.length > 0 &&(
             <Grid item xs={12} md={6} lg={6} >  
          <Card style={{padding: '20px'}}>
          {item.tipps.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                    {line}
                    <br />
                    </React.Fragment>
                ))}
            <p style={{textAlign: 'right', color:'#999'}}>{`${item.name} (${item.sws}-${item.jahr})`}</p>
            </Card>
            </Grid>)
        ))}
        </Grid>
        </div>
      </Card>
      </Grid>


      
      </Grid>
      
      <Snackbar open={uploadResult !== null} autoHideDuration={3000} onClose={handleSnackbarClose}>
          {uploadResult !== null && (<Alert onClose={handleSnackbarClose} severity={(uploadResult === 'success' || uploadResult===true ) ? 'success' : 'error'}>
            {(uploadResult === 'success' || uploadResult===true ) ? 'Files uploaded successfully!' : 'Failed to upload files.'}
          </Alert>)}
      </Snackbar>
    </>
  );
}
