import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { styled } from '@mui/material/styles';
import { Card, CardHeader, IconButton , Popover} from '@mui/material';

// components
import { useChart } from '../../../components/chart';

import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;

const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

const open = true;

const handleOpenMenu = (event) => {
  //  setOpen(event.currentTarget);
};

const handleCloseMenu = () => {
  //  setOpen(null);
};

// ----------------------------------------------------------------------

AppCurrentSubject.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppCurrentSubject({ title, subheader, chartData, chartColors, chartLabels, open, transparency, ...other }) {
  const chartOptions = useChart({
    stroke: { width: 2 },
    fill: { opacity: transparency },
    legend: { floating: true, horizontalAlign: 'center' },
    xaxis: {
      categories: chartLabels,
      labels: {
        style: {
          colors: chartColors,
        },
      },
    },
    yaxis: {
      labels: {
        enabled: true,
        formatter (value) {
          if (value > 1){
            return value;
          }
          return value.toFixed(2); // Rounds value to two decimal places in the tooltip label
        },
      },
    },
  });
  

  return (
    <Card style={{height: '100%'}} {...other}>
      <CardHeader title={title} subheader={subheader} />
        <IconButton size="large" color="inherit" onClick={open} style={{float: 'right', marginTop: '-40px', marginRight:'10px'}}>
        <Iconify icon={'fluent:settings-20-filled'} id={"coSettings"} />
        </IconButton>

      <StyledChartWrapper dir="ltr">
        <ReactApexChart type="radar" series={chartData} options={chartOptions} height={340} />
        
      </StyledChartWrapper>

    </Card>

    
  );
}
