
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState ,useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  Grid,
  Fab,
  MenuItem,
  Pagination,
  Chip,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CardHeader,
} from '@mui/material';
// components
import { useLocation } from 'react-router-dom';

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock

import { fShortenNumber } from '../utils/formatNumber';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'count', label: 'Einträge', alignRight: false },
  { id: 'klausurname', label: 'Klausurname', alignRight: false },
  { id: 'studiengang', label: 'Studiengang', alignRight: false },
  { id: 'cp', label: 'CP', alignRight: false },
  { id: 'aufwand', label: 'Aufwand', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, subject) {
  console.log(subject)
  if(subject.length > 0 && subject !== "Alle"){
    array  = filter(array, (_user) =>  _user.studiengang.includes(subject))
  }
  console.log(array)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.klausurname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DashboardAppPage() {
  const Fav = styled('fab')(({ theme }) => ({
    position: 'fixed',
    zIndex: 500,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }));

  const [USERLIST, setUserList] = useState([]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(1);
  const [subjects, setSubjects] = useState([]);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('klausurname');

  const [filterName, setFilterName] = useState('');
  const [subject, setSubject] = useState('');
  
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.klausurname);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50); 
  };


  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName, subject);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('deltatoken');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch('https://corpsdelta.study/api/get_reports', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
      setUser(response.json().user);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })



  }, []);
  
  async function fetchData(url) {
    const token = localStorage.getItem('deltatoken');
    console.log(token)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    const data = await response.json();
    return data;    
  }
  
  useEffect(() => {  
    async function fetchUsers() {
      const  profiles  = await fetchData('https://corpsdelta.study/api/get_reports');
      console.log(profiles)
      setUserList(JSON.parse(profiles));
    }
    fetchUsers();
  }, []);
  
  useEffect(() => {  
    async function fetchUsers() {
      const  subjects  = await fetchData('https://corpsdelta.study/api/get_subjects');
      console.log("subjects")
      console.log(subjects)
      setSubjects([{studiengang: "Alle", count: USERLIST.length}, ... JSON.parse(subjects)]);
    }
    fetchUsers();
  }, []);

  const handleOpenReport = (item) => {
    console.log(item)
    window.location.href = `/dashboard/report#${item.klausurname}`;
  };
  const removeSubject = () => {
    console.log("item")
     setSubject("")
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <Helmet>
        <title> Delta Studies </title>
      </Helmet>

      <Fav>
        <a href={`/dashboard/newpage`}>
      <Fab color="secondary" aria-label="add">
          <Iconify  icon={'bi:plus'}  color="#fff" width={32} />
      </Fab></a>
      </Fav>

      <Container>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <p style={{marginLeft: '25px', 
            float: subject.length === 0 ? undefined : 'left',}}>{filteredUsers.length} Klausuren</p>
          {subject.length > 0 && (<Chip          
          label={subject}
          onDelete={removeSubject}
          style={{
            background: '#e1e4e9',
            color: '#061b64',
            marginLeft:'25px',
            marginBottom: '20px'
          }}
          />)}
          <Scrollbar>


            {filterName.length === 0 && subject.length === 0 && (
              <div  style={{marginTop: '0px', marginLeft: '25px', marginRight: '25px', paddingBottom: '20px'}}>
          <Grid container spacing={2} >       
          {subjects.map((item, index) => {
              const isHovered = index === hoveredIndex;

              const cardStyle = {
                height: '210px',
                cursor: 'pointer',
                backgroundColor: isHovered ? '#490b0b' : '#e1e4e9', 
                color: isHovered ? 'white' : 'black', 
              };

              return (
                <Grid item xs={12} md={6} lg={4} key={item.id}>
                  <Card
                    style={cardStyle}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => {
                      setSubject(item.studiengang);
                      setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }, 50); 
                    }}
                  >
                    <CardHeader title={item.studiengang} style={{ textAlign: 'center' }} />
                    <Avatar sx={{ position: 'relative' , width: '120px',
                        height: '120px',
                        background: '#fff0',
                        margin: '0 auto',
                        marginBottom: '10px',}}>
                            <img
                              style={{ objectFit: 'cover', zIndex: 5 , padding: '10px'}}
                              src={`/assets/university.png`}
                              alt="T"
                            />
                            
                          </Avatar>
                          <p style={{textAlign: 'center', marginTop: "-30px"}}>{item.studiengang === "Alle" ? USERLIST.length : item.count} Berichte</p>
                  </Card>
                </Grid>
              );
            })}

            </Grid></div>)}


            {(filterName.length > 0  || subject.length > 0) && (
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage).map((row) => {
                    const handleRowClick = () => {
                      handleOpenReport(row); // Pass the entire row data to the function
                    };
                    return (
                      <TableRow hover key={row.id} tabIndex={-1} role="checkbox" onClick={handleRowClick}>
                        
                        <TableCell align="left" >{row.count}</TableCell>
                        <a href={`/dashboard/report#${row.klausurname}`}>
                        <TableCell align="left">{row.klausurname}</TableCell></a>
                        <TableCell align="left" style={{maxWidth: '200px'}}>
                        { row.studiengang.split(',').map((item) => (
                          <Chip label={item} style={{marginBottom: '5px'}}/>
                        )) }
                        </TableCell>
                        <TableCell align="left">{row.cp}</TableCell>
                        <TableCell align="left"> <progress id="file" value={row.aufwand} max="100"> {row.aufwand}% </progress></TableCell>
                       

                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Keine Klausuren gefunden
                          </Typography>

                          <Typography variant="body2">
                             &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> {(subject.length === 0 && subject !== "Alle") ? "Checke auf Tippfehler": "Erweitere Suche auf alle Studiengänge"}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>)}
          </Scrollbar>
          {(filterName.length > 0  || subject.length > 0) && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
          <Pagination
              color="primary"
              shape="rounded"
              count={Math.ceil(filteredUsers.length / rowsPerPage)}
              page={page}
              style={{ margin: '0 auto' }}
              onChange={handleChangePage}
            />
            </div>)}
        </Card>
      </Container>

    </>
  );
}