import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled  , alpha} from '@mui/material/styles';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Box,
    TableRow,
    LinearProgress,
    Link, ListItem, ListItemText,
    MenuItem,
    TextField,
    TableBody,
    TableCell,
    TypographyStyle,
    Typography,
    CardHeader,
    ListItemSecondaryAction, 
    Container,
    Grid,
    Dialog, DialogTitle, DialogContent,
    IconButton,
    Snackbar ,
    Fab,
    Alert,
    TableContainer,
    TablePagination,
  } from '@mui/material';  

//  import { Button, Alert, Snackbar,Typography, Container,  Box, Link, Card, List, IconButton, Avatar, Stac } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';


import Iconify from '../components/iconify';
import NavSection from '../components/nav-section';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
 
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: "30px"
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const TabNames = ["Fässer", "Bier", "fuchsenkiosk", "wasser/Pullen", "limo", "Wäsche"]
const BackColors = ["#144b74","#bc6c25", "#5b9c6b", "#669cb6", "#7c538f", "#bfa82c"]
const buttons = TabNames.map(() => React.createRef());

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
export default function Upload() {
    useEffect(() => {
        const token = localStorage.getItem('deltatoken');
        if (!token) {
          window.location.href = '/login';
          return;
        }
        fetch('https://corpsdelta.study/api/protected', {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error);
          window.location.href = '/login';
        })    
      }, []);

  const [downloading, setDownloading] = useState(false);
  const [faesser, setFaesser] = useState([]);

  const handleSnackbarClose = () => {
    handleUploadResult(null);
  };
    const [uploadResult, handleUploadResult] = useState(null);
  
  

   
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    
    
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
    };
    const handleUpload = async () => {
      setLoading(true);
      setUploadProgress(0)

        const formData = new FormData();
        formData.append('file', file);

        fetch('https://corpsdelta.study/api/admin/upload_table', {
        method: 'POST',
        body: formData,
        })
        .then((response) => response.json())
        .then((response) => {
            
            setLoading(false);
            setDialogOpen(false);
            handleUploadResult('success');
          console.log('File uploaded successfully:', response.data);
          })
          .catch((error) => {
          console.error('Error uploading file:', error);
          handleUploadResult('failure');
          setDialogOpen(false);
          setLoading(false);
          });
    };
    const downloadCsv = async () => {
        const token = localStorage.getItem('deltatoken');
      
        setDownloading(true)
        const response = await fetch('https://corpsdelta.study/api/admin/excel', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        
        if (response.ok) {
          if (data.notMatched.length > 0)
            alert(`ERROR! Folgende Corpsbrüder wurden nicht gemacht: ${data.notMatched}`);
          window.location.href = `https://corpsdelta.study/api/kassenexl/${data.path}`
        } else {
          console.error('Failed to download CSV file');
        }
        setDownloading(false)
      
      };
  const navConfig = [
    {
      title: 'Übersicht',
      path: '/kasse',
      icon: <Iconify icon={'mdi:money'} color='#000' />,
    },
    {
      title: 'Backup',
      path: '/backup',
      icon: <Iconify icon={'mdi:backup'} color='#000' />,
    },
    {
      title: 'Upload Excel',
      path: '/upload',
      icon: <Iconify icon={'mdi:upload'} color='#000' />,
    },
    {
      title: 'Logout',
      path: '/login',
      icon: <Iconify icon={'mdi:logout'} color='#000' />,
    }]
  return (
    <>
      <Helmet>
        <title> Kasse </title>
      </Helmet>
    <div style={{width: "300px", position: "fixed", background: "#ebeef1", height: "100%", bottom: 0, top: 0, left: 0, zIndex: 300}}>
      <Box sx={{ mb: 5, mx: 2.5, marginTop: "20px" }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={"a"} alt={"Kasse"}/>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                Kasse
              </Typography>

              
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <NavSection data={navConfig} />
    </div>

      <Container style={{marginLeft: "300px"}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center', marginLeft: "0px" }}>

          <Typography variant="h3" paragraph >
           Delta Drinks Kasse
          </Typography>
          

          <Card style={{padding: "30px", width: "100%", textAlign: "left"}}>
          <Typography variant="h5" paragraph>
           1. Download Vorlage
          </Typography>
          <Typography style={{marginTop: "-10px", marginBottom: "10px"}} >
           Bisherige Vorlage herunterladen
          </Typography>
          
          <Button style={{marginLeft: "20px", height:'50px'}}  variant="contained" color="secondary"   onClick={() => {window.location.href = `https://corpsdelta.study/api/kassenexl/vorlage.xlsx`}}>
              Download
            </Button>
          </Card>

          <Card style={{padding: "30px", width: "100%",  marginTop: "30px", textAlign: "left"}}>
          <Typography variant="h5" paragraph>
           2. Kopiere die Namen der Corpsbrüder in die Vorlage, die bei der Kasse übernommen werden sollen
          </Typography>
          
          <Typography style={{marginTop: "-10px", marginBottom: "10px"}} >
           Die Reihenfolge und Anzahl der Namen sollte der in der finalen Abrechnungsexcel Tabelle übereinstimmen, sodass die Striche nachher einach in diese kopiert werden können.
          </Typography>
          </Card>

          <Card style={{padding: "30px",  width: "100%", marginTop: "30px", textAlign: "left"}}>
          <Typography variant="h5" paragraph>
           3. Upload aktualisierte Excel-Tabelle um die Namen der Corpsbrüder zu matchen
          </Typography>
          
          
            <input type="file" id="uploadFile" onChange={handleFileChange} style={{ display: loading ? 'none' : undefined }}/>

            <Button style={{marginLeft: "20px", height:'50px'}}  variant="contained" color="secondary"   onClick={handleUpload}  disabled={loading}>
              Upload
            </Button>
            </Card>

            
          <Card style={{padding: "30px", width: "100%",  marginTop: "30px", textAlign: "left"}}>
          <Typography variant="h5" paragraph>
           4. Download Strichliste der Corpsbrüder
          </Typography>
          <Typography style={{marginTop: "-10px", marginBottom: "10px"}} >
           Die Corpsbrüder die in der hochgeladenen Vorlage stehen werden mit denen des digitalen Strichlistensystem gematcht.
          </Typography>
          <Button style={{marginLeft: "20px", height:'50px'}}  variant="contained" color="secondary" onClick={downloadCsv}>
              Download
            </Button>
          </Card>
          <Snackbar open={uploadResult !== null} autoHideDuration={3000} onClose={handleSnackbarClose}>
          {uploadResult !== null && (<Alert onClose={handleSnackbarClose} severity={(uploadResult === 'success' || uploadResult===true ) ? 'success' : 'error'}>
            {(uploadResult === 'success' || uploadResult===true ) ? 'Files uploaded successfully!' : 'Failed to upload files.'}
          </Alert>)}
      </Snackbar>
        </StyledContent> 
      </Container>
    </>
  );
}
