import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled  } from '@mui/material/styles';

import { Button,  Card,Typography, Container, Box } from '@mui/material';
import { useState ,useEffect, useRef  } from 'react';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
 
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: "30px"
}));

// ----------------------------------------------------------------------

const TriangleImage = styled('img')({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',  // Equilateral triangle points upwards
});
const TriangleContainer = styled('div')({
  position: "relative",
  width: "600px",
  height: "400px",
  marginTop: "50px"
});
export default function Imprint() {
  
  useEffect(() => {
    const token = localStorage.getItem('deltatoken');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch('https://corpsdelta.study/api/protected', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  const [downloading, setDownloading] = useState(false);
  const [activateDownload, setActivateDownload] = useState(false);

  const downloadCsv = async () => {
    const token = localStorage.getItem('deltatoken');
  
    setDownloading(true)
    const response = await fetch('https://corpsdelta.study/api/get_all_exams', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      console.error('Failed to download CSV file');
    }
    setDownloading(false)
  
  };
  return (
    <>
      <Helmet>
        <title> Imprint </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>

          <Typography variant="h3" paragraph onClick ={() => setActivateDownload(true)} >
           Thore Koritzius
          </Typography>

          <Typography sx={{ color: 'text.secondary', marginBottom: "10px" }}>
            
            Studienerfahrungs-Plattform <br/> <br/>
            thorekoritzius@outlook.de<br/>
            (c) 2023 build with React <br/>
          </Typography>
          
          {activateDownload && (<Button variant="contained" onClick={downloadCsv} disabled={downloading}>Download All</Button>)}
          <TriangleContainer>
      <TriangleImage src={"/assets/delta-background.jpeg"} alt="Triangle" /></TriangleContainer>

        </StyledContent>
      </Container>
    </>
  );
}
