import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled  } from '@mui/material/styles';
import debounce from 'lodash.debounce';

import { Button,Dialog, IconButton , DialogContent,Fab, DialogTitle,TextField, FormControlLabel, Grid, CircularProgress, CardHeader, Avatar, Card,Typography, Container, Box, Checkbox } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
// ----------------------------------------------------------------------
import Iconify from '../components/iconify';
import styles from './Bubble.css'; 

export default function DrinksTablet() {
  const Fav = styled('fab')(({ theme }) => ({
    position: 'fixed',
    zIndex: 500,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }));
    const fields = useRef([]);

    const TabNames = ["Bier", "fuchsenkiosk", "wasser/Pullen", "limo", "Wäsche", "Stats"]
    const BackColors = ["#bc6c25", "#5b9c6b", "#669cb6", "#7c538f", "#bfa82c", "#144b74"]
    
    const buttons = TabNames.map(() => React.createRef());

    const images = ["bier", "shop", "water", "water", "laundry", "copies", "fass", "glas"];

    const [currHighlightedUsername, setCurrHighlightedUsername] = useState("");
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [available, setAvailable] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogHighscores, setDialogHighscores] = useState(false);
    const [dialogOpenPeople, setDialogOpenPeople] = useState(false);
    const [dialogOpenFreifass, setDialogOpenFreifass] = useState(false);
    const [showFreifass, setShowFreifass] = useState(false);
    const [fuchsenkiosk, setFuchsenkiosk] = useState(0);
    const [tempFreifassName, setTempFreifassName] = useState("");
    const [names, setNames] = useState(Array(80).fill({ name: "", striche: 0 }));
    const [pureNames, setPureNames] = useState(Array(80).fill(""));
    const [limo, setLimo] = useState(0);
    const [allNames, setAllNames] = useState([]);
    const [waesche, setWaesche] = useState(0);
    const [fass, setFass] = useState(0);
    const [freifass, setFreifass] = useState("");
    const [countStriche, setCountStriche] = useState(0);
    const [glas, setGlas] = useState(0);
    const [wasser, setWasser] = useState(0);
    const [kopien, setKopien] = useState(0);
    const [bier, setBier] = useState(0);
    const [currIndex, setCurrIndex] = useState(0);
    const [count, setCount] = useState(0);
    const [freifassLoading, setFreifassLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [currTabIndex, setCurrTabIndex] = useState(0);
    const [highscores, setHighscores] = useState([]);
    const [faesser, setFaesser] = useState([]);
    const [displayHighscores, setDisplayHighscores] = useState([]);
    const [currColor, setCurrColor] = useState("#bc6c25");
    const easterEgg = {"Fasler": "Fasler mach das Fass leer!", "Koritzius": "Thornado", "Sivakov": "Siva-Suffkopf", "Meng": "Meng kann nicht sauphen", "Niggemann": "Nigel"}
    const [activateEasterEgg, setActivateEasterEgg] = useState({});
    function drawStrokes(c, count) {
        
        if(c=== null)
            return 0;
        const ctx = c.getContext('2d');
        
        let top = 0;
        //  c.width  = (ctx.getBoundingClientRect().width ) * 0.8 ;
        //  c.width  = 100;
        c.width = c.clientWidth;
        c.height = 50;
        let start = 1;
        if(count < 50){
            start = 250 - (count*10/2);
        }
        ctx.scale(0.5, 0.5);
            for(let i=1; i <= Math.min(count,150); i+=1){
                ctx.beginPath();
                ctx.lineWidth = "4";
                ctx.strokeStyle = currColor;
                
                start+=10;
                if(i%5 === 0 && i > 1){
                    ctx.beginPath();
                    ctx.moveTo(start-50, 10+top);
                    ctx.lineTo(start, 25+top);
                    ctx.stroke();
                }else{		
                    ctx.rect(start, 10+top, 1, 20);
                    ctx.stroke();
                }
                if(i===50){
                    top+=40;
                    start = 1;
                }
                if(i===100){
                    top+=40;
                    start = 1;
                }
            }
            return 0;
    }
    async function postData(url, requestBody) {
        const token = localStorage.getItem('deltatoken');
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
        });            
        if(response.status === 401){
            window.location.href = '/login';
        }
        if(response.ok){
        const data = await response.json();
        return data;
        }
        return null;
    }
      
    async function fetchData(url) {
      const token = localStorage.getItem('deltatoken');
      console.log(token)
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      if(response.status === 401){
          window.location.href = '/login';
      }
      const data = await response.json();
      return data;    
    }
    
    function parseStriche(input){
      if(input===null)
      return 0;
        setLoading(true);
        const items = JSON.parse(input);
        console.log(items)
        setFreifass("")
        setShowFreifass(false)
        
        const tempNames = items.filter((item) => !("freifass" in item)).map((item) => ({
            name: item.corpsbruder,
            striche: item.count,
            id: item.id
          }));
        setNames(tempNames);
        setPureNames((tempNames).map((item) => (item.name in activateEasterEgg ? activateEasterEgg[item.name] : item.name)))
        setCountStriche(tempNames.reduce((sum, obj) => sum + obj.striche, 0))
        
        console.log("HEYHEY")
        console.log(items.length)
        for(let i = 0; i< items.length; i+=1){
          console.log(items[i].count)
          try{
            const canvas = document.getElementById(`field_${i}`);
            drawStrokes(canvas, tempNames[i].striche);
          }catch(e){
            console.log("Err")
          }
        }
        
        if(currTabIndex === 0 && "freifass" in items[items.length -1]){
          console.log("FREIFASS")
          setFreifass(items[items.length -1].corpsbruder)
          //  setShowFreifass(true);
        }
        setLoading(false);
      
        return 0;
    }
    const showHighscores = async () => {
      const input = await postData('https://corpsdelta.study/api/get_all_striche', { input: 5 });
      
      const splitHighscores = JSON.parse(input).reduce((result, currentScore) => {
        const existingCategory = result.find(category => category[0].category === currentScore.category);
        
        if (existingCategory) {
          existingCategory.push(currentScore);
        } else {
          result.push([currentScore]);
        }
        
        return result;
      }, []);
      console.log(splitHighscores)
      setHighscores(splitHighscores);
      setLoading(false);
      setLoading2(false);
      setDisplayHighscores(true);
      setDialogHighscores(true);
    }

    
    useEffect(() => {
      const getAllNames = async () => {
        let input = await postData('https://corpsdelta.study/api/get_all_names', {input: "all"});
        input = JSON.parse(input);
        console.log(input)
        if(input!== null)
          setAllNames(input);
      }
      getAllNames();
    }, [dialogOpenPeople]);
    
    useEffect(() => {
      const fetchStriche = async () => {
        setLoading(true);
        const input = await postData('https://corpsdelta.study/api/get_all_striche', { input: currTabIndex });
        parseStriche(input);
        setLoading(false);
      }
      fetchStriche();
    }, [currTabIndex]);
      
    
      const saveClose = async() => {
        setLongClickedIndex(null);
        setDialogOpen(false);
        const  input  = await postData('https://corpsdelta.study/api/post_transaction_admin', {category: currTabIndex, amount: count, user: names[currIndex].id});
        parseStriche(input)
      };
      const saveCloseFreifass = async() => {
        setFreifassLoading(true);
        if(tempFreifassName.length <= 2){
          alert("Freifassname is zu kurz!")
          return;
        }
        const input = await postData('https://corpsdelta.study/api/neues_freifass', {input: tempFreifassName});
        parseStriche(input)
        setDialogOpenFreifass(false);    
        clearTimeout(timerFreifass); 
        setLoading2(false);
        setFreifassLoading(false);

      };
      const handleClose = () => {
        setLongClickedIndex(null);
        setDialogOpen(false);
      };
      const handleCloseFreifass = () => {
        setDialogOpenFreifass(false);
      };
      const handleClosePeople = () => {
        setDialogOpenPeople(false);
      };
      const saveClosePeople = async() => {
        setDialogOpenPeople(false);
        console.log(JSON.stringify({ input: allNames.slice(2) }));

        const input = await postData('https://corpsdelta.study/api/update_names', {input: allNames});
        window.location.reload();
      };
      const [isMobile, setIsMobile] = useState(false);

      useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 500);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      const handleIncrement = (c) => {
        if (count + c < 999)
        setCount(count + c);
        else
        setCount(999)
      };
      const handleDecrement = () => {
        if(count > 0){
            setCount(count -1);
        }
      };
      const closeHighscores = () => {
        setDialogHighscores(false)
      };
      
  const [isLongPressing, setIsLongPressing] = useState(false);

  let longPressTimeout;
  let touchMoved = false; 

  const handleMouseDown = (index) => {
    console.log("handleMouseDown");
    touchMoved = false; // Reset touchMoved flag
    longPressTimeout = setTimeout(() => {
      if (!touchMoved) {
      // Perform your long-press action here
      setCount(names[index].striche)
      setCurrHighlightedUsername(pureNames[index])
      setDialogOpen(true)
      setCurrIndex(index);
      setIsLongPressing(true);
      setLongClickedIndex(index)
      }
    }, 800); // Set the desired long-press duration in milliseconds
  };
  const handleTouchMove = () => {
    touchMoved = true; // Set touchMoved flag to true when there is touch movement
    clearTimeout(longPressTimeout); // Cancel the long press if there is touch movement
  };
  
  const handleMouseUp = () => {
    clearTimeout(longPressTimeout);
    if (isLongPressing) {
      // Handle the end of the long-press action here
      setIsLongPressing(false);
    }
  };

  useEffect(() => {  
    if(dialogOpen){
        clearTimeout(longPressTimeout);
        if (isLongPressing) {
        setIsLongPressing(false);
        }
    }
  }, [dialogOpen]);

  let timerFreifass;
  useEffect(() => {

    if (loading) {
      timerFreifass = setTimeout(() => {
        setLoading2(true);
      }, 1000);
    } else {
      setLoading2(false);
    }

    return () => {
      clearTimeout(timerFreifass);
    };
  }, [loading]);

  const switchTab = (index) => {
    try{
      for(let i = 0; i < buttons.length; i+=1){
        if(buttons[i] !== undefined && buttons[i].current !== undefined)
          buttons[i].current.style.backgroundColor  = '#000'
      }  
    }catch(e){
      console.log("err")
    }
    try{    
      if(buttons[index] !== undefined && buttons[index].current !== undefined)
        buttons[index].current.style.backgroundColor  = BackColors[index];
    }catch(e){
      console.log("err")
    }
    setCurrTabIndex(index)
    setCurrColor(BackColors[index])
  }
  
  const neuesFass = async() => {
    const result = window.confirm('Sicher das du zu einem neuen Fass wechseln möchstest?');
    if (result) {
        const input = await postData('https://corpsdelta.study/api/neues_fass', {input: (freifass.length === 0 ? "false" : "true")});
        parseStriche(input);
        setLoading2(false);

    }
  }

  const [clickedIndex, setClickedIndex] = useState(null);
  const [longClickedIndex, setLongClickedIndex] = useState(null);

  const handleRowClick = async(index) => {
    if(freifass.length ===0){
    const  input  = await postData('https://corpsdelta.study/api/post_transaction_admin', {category: currTabIndex, amount: names[index].striche + 1, user: names[index].id});
    parseStriche(input)
    setClickedIndex(index);

    setTimeout(() => {
      setClickedIndex(null);
    }, 150);
  }
  };

  const StyledContent = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: "100px"
  }));
  const btnStyle = {background: '#222', color: '#ccc', borderRadius: "0px",width: "25%", height: "70px", border: "1px solid #aaa" ,padding: "10px"}
  const btnStyleRunde = {background: '#222', color: '#ccc', borderRadius: "0px",width: "100%", height: "70px", border: "1px solid #aaa" ,padding: "10px"}
  const mutedColors = [
    '#A18B76', // '#C9B4A5' darkened
    '#8B9EA9', // '#AEC6CF' darkened
    '#9EA39C', // '#B5C7B7' darkened
    '#A6958E', // '#D3C4B7' darkened
    '#9AA6BD', // '#B6C3D1' darkened
];

  const [currentColorIndex, setCurrentColorIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % mutedColors.length);
    }, 60000); 

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const loadFaseer = async() => {
      const  profiles  = await fetchData('https://corpsdelta.study/api/admin/faesser');
      console.log(profiles)
      setFaesser(JSON.parse(profiles));
    }
    loadFaseer();
  }, [dialogOpenFreifass]);

  //  Easter Egg display alternative usernames, only in frontend
  const handleEasterEgg = ()=> {
      if(names[currIndex].name in activateEasterEgg){
        const updatedEasterEgg = { ...activateEasterEgg };
        delete updatedEasterEgg[names[currIndex].name ];
        setActivateEasterEgg(updatedEasterEgg);
        setCurrHighlightedUsername(names[currIndex].name)
      }else if(names[currIndex].name in easterEgg){
        const updatedEasterEgg = { ...activateEasterEgg, [names[currIndex].name]:`${easterEgg[names[currIndex].name]}` };
        setActivateEasterEgg(updatedEasterEgg);
        setCurrHighlightedUsername(easterEgg[names[currIndex].name])
      }    
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const currentColor = mutedColors[currentColorIndex];  
  
  const handleCheckboxChange = (index) => {
    setAllNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames[index] = {
        ...updatedNames[index],
        is_displayed: !updatedNames[index].is_displayed,
      };
      return updatedNames;
    });
  };

  return (
    <div style={{WebkitUserSelect: "none", MozUserSelect: 'none', msUserSelect: 'none',  userSelect: 'none'}}>
      <Helmet>
        <title> Delta Drinks </title>
      </Helmet>
      
      <Fav>
      <Fab color="secondary" aria-label="add" onClick={() => setDialogOpenPeople(true)} style={{width: "70px", height: "70px"}}>
          <Iconify  icon={'bi:people'}  color="#fff" width={40} />
      </Fab>
      </Fav>
      <Dialog open={dialogOpenPeople}  disableBackdropClick disableEscapeKeyDown 
      PaperProps={{
        style: {
          position: 'fixed',
          height: '100%',
          background: '#222',
          color: '#fff',
          minWidth: '440px',
          overflow: 'auto', // Enable scrolling if the content exceeds the viewport
        },
      }}>
      <div style={{ background: '#222', color:'#fff', minWidth: "440px"}}>
       <DialogTitle style={{color: '#fff', fontSize: 30, textAlign: "center", padding: "20px"}}>Angezeigte Corpsbrüder ändern</DialogTitle>
       <div style={{zIndex: 500, display: "flex", flexDirection: "row", justifyContent: "center", position: "fixed", bottom: 10, left: "50%", transform: "translateX(-50%)", width: "100%", maxWidth: "330px" }}>
          <Button style={{ flexBasis: "48%", height: "70px", color: '#26b4ec', background: '#454545', border:"1px solid black" }} onClick={handleClosePeople}>
            Abbrechen
          </Button>
          <Button variant="contained" style={{ flexBasis: "48%", height: "70px", background: '#0a2f69' , border:"1px solid black" }} onClick={saveClosePeople}>
            Speichern
          </Button>
        </div>
        
        <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginLeft: "20px",
          marginRight: "20px",
          flexWrap: "wrap",
        }}
      >
        
        <Button variant="contained" style={{ flexBasis: "100%", height: "50px", background: '#111' , border:"1px solid black", marginBottom: "20px" }}>
            Neuen Namen hinzufügen
          </Button>
        {allNames.map((item, index) => (
          <div
            key={item.id}
            role="button"
            tabIndex={0}
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "48.5%",
              boxSizing: "border-box",
            }}
            
            onClick={() => handleCheckboxChange(index)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCheckboxChange(index);
              }
            }}
          >
            <span
              style={{
                padding: "17px",
                borderRadius: "4px",
                color: "#fff",
                background: "#454545",
                width: "100%",
                backgroundColor: item.is_displayed ? "#0a2f69" : "#454545",
              }}
            >
              {item.username}
            </span>
          </div>
        ))}
        <div style={{padding: "70px"}} />
      </div>



        </div>
      </Dialog>

    <Dialog open={dialogOpenFreifass}  disableBackdropClick disableEscapeKeyDown PaperProps={{
        style: {
          position: 'fixed',
          width: '100%',
          background: '#222',
          color: '#fff',
          minWidth: '440px',
          overflow: 'auto', // Enable scrolling if the content exceeds the viewport
        },
      }}>
      <div style={{ background: '#222', color:'#fff', minWidth: "530px"}}>
       <DialogTitle style={{color: '#fff', fontSize: 30, textAlign: "center"}}>Freifass geben</DialogTitle>
            
       <TextField
          value={tempFreifassName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue.length <= 100) {
              setTempFreifassName(inputValue);
            }
          }}
          fullWidth
          multiline
          style={{ background: '#eee', padding: '10px' }}
          placeholder='Freifass Name'
          inputProps={{ maxLength: 100 }}
        />
          <div  style={{display: freifassLoading? "flex" : "none", color: "#fff", marginBottom: "-10px", height: "100px", padding:"10px", justifyContent: "center", alignItems: "center", flexDirection:"column"}} >
          <CircularProgress id="my-spinner" style={{color: "#fff"}}/></div>
            <div style={{marginTop:"10px"}}>
            <Button  disabled={freifassLoading}  style={{width: "50%", height: "70px", color: '#26b4ec', background: '#454545'}} onClick={handleCloseFreifass}>Abbrechen</Button>
            <Button  disabled={freifassLoading}  variant="contained" style={{width: "50%", height: "70px", background: '#0a2f69'}} onClick={saveCloseFreifass}>Speichern</Button></div>
        </div>

        <DialogTitle style={{color: '#fff', fontSize: 30, textAlign: "center"}}>Bisherige Fässer</DialogTitle>
        <table style={{ width: '100%', padding: '25px',  marginTop: '5px', color: "#eee"}}>
                <tbody>
                  
                    <tr>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Nr</td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Striche</td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Freifassname</td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Date</td>
                    </tr>
                    
                {faesser.map((item, index) => (
                  <tr key={index}>
                  <td style={{  padding: '10px', border: '1px solid #000' }}>
                    {item.id}
                  </td>
                  <td style={{  padding: '10px', border: '1px solid #000', color: (item.striche < 100 ? '#f00' : undefined) }}>
                    {item.striche}
                  </td>
                  <td style={{  padding: '10px', border: '1px solid #000' }}>
                    {item.freifass_name}
                  </td>
                  <td style={{  padding: '10px', border: '1px solid #000' }}>
                    {item.date}
                  </td>
                  
                </tr>
                ))}

                </tbody>

              </table>
      </Dialog>

      <Dialog open={dialogOpen}  disableBackdropClick disableEscapeKeyDown PaperProps={{
        style: {
          position: 'fixed',
          width: '100%',
          background: '#222',
          color: '#fff',
          minWidth: '440px',
          overflow: 'auto', // Enable scrolling if the content exceeds the viewport
        },
      }}>
      <div style={{ background: '#222', color:'#fff', minWidth: "330px"}}>
       <DialogTitle style={{color: '#fff', fontSize: 35}}  onClick={() => handleEasterEgg()}>{currHighlightedUsername}</DialogTitle>
       <p style={{color: '#fff', marginLeft: "25px", marginTop: "-15px"}}>Bisher {names[currIndex].striche} Striche bei {TabNames[currTabIndex]}.<br/> Gebe insgesamte Strichanzahl an:</p>
       <div style={{ textAlign: 'center', fontSize: '44px', padding: '20px' }}>
       <IconButton onClick={handleDecrement}>
        	<Iconify icon="eva:minus-outline" width={64} style={{marginRight: "30px"}}/>
          </IconButton>
          {count}
          <IconButton onClick={() =>handleIncrement(1)} style={{marginLeft: "30px"}}>
          <Iconify icon="eva:plus-outline"  width={64}/>
          </IconButton>
          </div>
          </div>
          <div style={{ textAlign: 'center', fontSize: '44px', padding: '20px', background: "#111" }}>
          <Button color={"primary"} style={btnStyle} onClick={() =>handleIncrement(4)} >+4</Button>
          <Button color={"primary"} style={btnStyle} onClick={() =>handleIncrement(8)} >+8</Button>
          <Button color={"primary"} style={btnStyle} onClick={() =>handleIncrement(16)} >+16</Button>
          <Button color={"primary"} style={btnStyle} onClick={() =>handleIncrement(32)} >+32</Button>
         {currTabIndex === 0 && (<Button color={"primary"} style={btnStyleRunde}  onClick={() => {window.confirm('Sicher das du eine Runde geben möchtest? \nACHTUNG: Bier trotzdem SEPERAT aufschreiben')}}>Runde Geben (0 verfügbar)</Button>)}
            <div style={{marginTop: "50px"}}>
            <Button  style={{width: "50%", height: "70px", color: '#26b4ec', background: '#454545'}} onClick={handleClose}>Abbrechen</Button>
            <Button  variant="contained" style={{width: "50%", height: "70px", background: '#0a2f69'}} onClick={saveClose}>Speichern</Button></div>
        </div>
      </Dialog>
      <div style={{position: "fixed", zIndex: 1000, width:"100%", paddingBottom: "5px", margin: "0em auto"}}>
        <div style={{ display: "flex"}}>
      {TabNames.map((item, index) => {
            return (
            <Button variant="contained" onClick={() => index === 5 ? showHighscores() : switchTab(index)} ref={buttons[index]} style={{  transition: "background-color 0.1s ease opacity 0.3s ease, filter 0.3s ease", height: "70px", width: (item === "Stats" ? "10%": "18%"),borderRadius: "0px", border: "1px solid #ccc", background: (item === "Bier" ? BackColors[0] : '#000') }}>{item}</Button>
            )
        })}</div>
        {freifass.length === 0 &&  (
        <div style={{height: "28px", textAlign: "center", color: "#fff", width: "100%",  transition: "background-color 0.5s ease, opacity 0.3s ease, filter 0.3s ease", background: BackColors[currTabIndex]}}>
            <p style={{background: "#0002", height: "100%", fontSize: 14, paddingTop: "3px"}}>{countStriche} {countStriche === 1 ? "Strich" : "Striche"}</p>
        </div>)}
      </div>
      
      <Dialog open={dialogHighscores} maxHeight="50px">
      <DialogContent style={{ maxHeight: '70vh', overflowY: 'auto', background: "#303030" }}>
          
        <Button onClick={closeHighscores} variant="contained" style={{ position: "fixed", height: "55px", width: "120px", zIndex: 100, bottom: 10, left: "50%", transform: "translateX(-50%)" }}>
          Close
        </Button>
        {
        highscores.map((categoryItems, categoryIndex) => {
          return (
              <div style={{maxWidth: "600px",margin: "0em auto", background: '#303030', width: "100%"}}>
              <p style={{ color: '#fff', fontSize: "30px" }}>{capitalizeFirstLetter(categoryItems[0].category)}</p>
              <Grid container spacing={1} style={{ paddingBottom: "100px" }}>
                {categoryItems.map((item, index) => {
                  const cardStyle = {
                    height: '100px',
                    cursor: 'pointer',
                    color: "#fff",
                    userSelect: "none",
                    backgroundColor: clickedIndex === index ? BackColors[currTabIndex] : '#1e1e1e',
                  };

                  const circleStyle = {
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#000',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '24px',
                    position: 'absolute',
                    zIndex: 10,
                    top: "25px",
                    left: '10px', 
                  };

                  return (
                    <Grid item xs={6} md={6} lg={6} key={item.id} style={{ position: 'relative' }}>
                      <Card style={cardStyle}>
                      <div style={circleStyle}>{index + 1}</div>
                      <div style={{background: BackColors[categoryIndex], position: "absolute", height: "100%", width: `${100 / Math.max(...categoryItems.map(x => x.count)) * item.count }%`, zIndex: -1}}/>

                        <CardHeader style={{marginLeft: "40px", color:"#fff"}} title={item.corpsbruder} subheader={<span style={{ color: '#ccc' }}>{`${item.count}`}</span>} />
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        })
        }
        </DialogContent>
        </Dialog>


      <div style={{backgroundColor: (!showFreifass ? '#303030' : currentColor), transition: (!showFreifass ? " background-color 0.3s ease, opacity 0.3s ease, filter 0.3s ease" : " background-color 30s ease, opacity 0.3s ease, filter 0.3s ease") , padding: "10px", paddingTop: "80px", pointerEvents: (loading ? "none" : undefined),  filter: (loading ? "blur(1px)" : undefined)}}>
      {showFreifass && (
      <section className={styles.sticky}>
        <div className="bubbles">
        <div className="bubble"/>
        <div className="bubble"/>
        <div className="bubble"/>
        <div className="bubble"/>
        <div className="bubble"/>
        <div className="bubble"/>
        <div className="bubble"/>

        </div>
        </section>)}

      
      <Grid container spacing={1} style={{marginTop: "40px", paddingBottom: "100px"}}>      
      {pureNames.map((item, index) => {
              const isHovered = index === hoveredIndex;
              const cardStyle = {
                height: '100px',
                cursor: 'pointer',
                color: "#fff",
                userSelect: "none",
                //  backgroundColor: isHovered ? '#333' : '#1e1e1e', 
                backgroundColor: clickedIndex === index ? BackColors[currTabIndex] : (longClickedIndex === index ? '#000' : '#1e1e1e'), 
              };
              return (
                <Grid item xs={6} md={6} lg={4} key={item.id}>
                  <Card
                    style={cardStyle}
                    onMouseDown={() => handleMouseDown(index)}
                    onMouseUp={handleMouseUp}
                    onTouchStart={() => handleMouseDown(index)}
                    onTouchEnd={handleMouseUp}
                    onTouchMove={handleTouchMove}
                    onClick={() => handleRowClick(index)}>

                    <p style={{float: "left", marginLeft: "20px", marginTop: "20px", fontSize: 20}}>{item}</p>
                    <p style={{padding: "5px", fontSize: 20, float: "right", marginRight: "10px"}}>{names[index].striche}</p>
                    <canvas id={`field_${index}`} style={{ width: '100%', paddingLeft: "20px", marginTop: "-20px" }} />
                  </Card>
                </Grid>
              );
            })}
            </Grid>
          
            <div style={{display: (freifass.length === 0? "none" : "flex"), background: currentColor, zIndex: 1, width: "100%",  position: "fixed", left: 0, right: 0, bottom: 0, top: 0}}/>
            <div
                style={{
                    marginTop: "-100px",
                    position: "fixed", zIndex: 1100, top: "50%",
                    left:"50%", transform: "translate( -50%)",
                    textAlign: "center", display: (freifass.length > 0 ?  "flex" : "none")
                }}
            >
                <Card style={{ background: '#000000bf', padding: '50px', backdropFilter: "blur(10px)" }}>
                <Typography variant="h1" paragraph style={{ color: '#fff', textAlign: "center" }}>
                    {freifass}
                </Typography>
                <Typography variant="h4" paragraph style={{ color: '#ccc', textAlign: "center" }}>
                    Freifass
                </Typography>
                </Card>
            </div>
                
            </div>

            { currTabIndex === 0 &&(<div style={{ position: "fixed", zIndex: 10,bottom: 0, left: 0, right: 0, background: '#303030', paddingTop: "10px"}}>
                <Card style={{height: "70px", background: '#303030', marginLeft: "7px", marginRight: "7px" , marginBottom: "3px"}}>
                <Button variant="contained" onClick={() => { setDialogOpenFreifass(true);setTempFreifassName("")}} style={{width: "50%", height: "100%", border: "1px solid #444", background: '#000'}}>Freifass</Button>
                <Button variant="contained" onClick={neuesFass} style={{width: "50%", height: "100%", border: "1px solid #444", background: '#000'}}>Neues Fass</Button></Card>
            </div>)}

            <div style={{position: "fixed", zIndex: 1000, top: "50%", height: "100vh",  
             left:"50%", transform: "translate( -50%)",
             textAlign: "center", display: (loading2 ?  "flex" : "none")}}>
            <CircularProgress  style={{color: '#fff'}}/>
            </div>
    </div>
  );
}
