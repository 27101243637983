// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    LinearProgress,
    List, ListItem, ListItemText,
    MenuItem,
    TextField,
    TableBody,
    TableCell,
    TypographyStyle,
    Typography,
    CardHeader,
    ListItemSecondaryAction, 
    Container,
    Grid,
    Dialog, DialogTitle, DialogContent,
    IconButton,
    Snackbar ,
    Fab,
    Alert,
    TableContainer,
    TablePagination,
  } from '@mui/material';   
  import { useState ,useEffect, useRef  } from 'react';

// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------


export default function Upload({ _examName, isMobile, handleUploadResult, IconSize, saveAllButton, saveReport, selectedFiles, setSelectedFiles, ...other }) {
    
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [examName, setExamName] = useState(_examName);
  const [finished, setFinished] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveFile = (fileIndex) => {
    const updatedFiles = selectedFiles.filter((_, index) => index !== fileIndex);
    setSelectedFiles(updatedFiles);
  };

  const saveData = async () => {
    const missing = await saveReport();
    if(missing.length === 0){
      await handleUpload();
      setFinished(true);
    }    
  };

  
  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      return;
    }
    setLoading(true);
    setUploadProgress(0)
  
    const formData = new FormData();
    formData.append('examName', examName);
  
    for (let i = 0; i < selectedFiles.length; i += 1) {
      formData.append('files', selectedFiles[i]);
    }
  
    const xhr = new XMLHttpRequest();
  
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(progress);
      }
    });
  
    try {
      xhr.open('POST', 'https://corpsdelta.study/api/upload_files');
  
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
            handleUploadResult('success');
        } else {
            handleUploadResult('failure');
        }
        setLoading(false);
        setDialogOpen(false); // Close the dialog after uploading
      };
  
      xhr.onerror = () => {
        console.error('Error uploading files');
        handleUploadResult('failure');
        setDialogOpen(false);
        setLoading(false);
      };
  
      xhr.send(formData);
    } catch (error) {
      console.error('Error uploading files:', error);
      handleUploadResult('failure');
      setDialogOpen(false);
      setLoading(false);
    }
    setSelectedFiles([]);
    
  };
  return (
    <>
    <div>
        
            {selectedFiles.length >0 && (
                <Card style={{background: '#eee', padding: "10px", maxWidth: "500px", marginBottom: "10px"}}> 
              <List>
                {selectedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)}>
                      <Iconify   icon={'mdi:delete'} color="#222" width={32} />
                      </IconButton>
                    </ListItemSecondaryAction>
                    <ListItemText primary={file.name} />
                    
                  </ListItem>
                ))}
              </List></Card>
            )}
            <input type="file" id="uploadFile" multiple onChange={handleFileChange} style={{ display: loading ? 'none' : undefined }}/>
            <LinearProgress variant="determinate" value={uploadProgress} style={{ display: loading ?  undefined : 'none'  }} />

            {(saveAllButton ===undefined || saveAllButton===false )&& <Button style={{marginLeft: "20px", height:'50px'}}  variant="contained" color="secondary"   onClick={handleUpload}  disabled={selectedFiles.length === 0 || loading}>
              Upload
            </Button>}

            {(saveAllButton && !finished ) && (<Button style={{width: '100%', marginTop:'30px', height:'50px'}}  variant="contained" color="secondary" onClick={saveData}>
              Speichern
            </Button>)}
            {(saveAllButton && finished ) && (<><br/><br/><br/><br/><p>Dein Bericht wurde erfolgreich gespeichert!</p><br/><a style={{width: "100%", textAlign: "center"}} href={`/dashboard/report#${_examName}`}>Bericht anzeigen</a></>)}
            
            </div>
    </>     

  );
}
