import React, { useEffect } from 'react';

import { Navigate, useRoutes, useLocation} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//

import NewReport from './pages/NewReport';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import Report from './pages/Report';
import Imprint from './pages/Imprint';
import DrinksPage from './pages/DrinksPage';
import DrinksTablet from './pages/DrinksTablet';
import Kasse from './pages/Kasse';
import Home from './pages/Home';
import Backup from './pages/Backup';
import Upload from './pages/Upload';
import {SearchContextProvider} from './context/SearchContext';

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();

  useEffect(() => {
    const updateBodyBackgroundColor = () => {
      
      if(location.pathname === '/tablet')
      document.body.style.backgroundColor = '#303030';
      else
      document.body.style.backgroundColor = '#F9FAFB';
    };

    updateBodyBackgroundColor(); // Set initial background color
    const unlisten = () => {
      // The cleanup function for removing the body background color update
      document.body.style.backgroundColor = '';
    };
    return unlisten;
  }, [location]);
  const routes = useRoutes([
    {
      path:  '/dashboard',
      element: (
        <SearchContextProvider>
          <DashboardLayout />
        </SearchContextProvider>
      ),
      children: [
        { element: <Navigate to={(localStorage.getItem('deltaUsernameDigital') === "CC" ? '/tablet' : '/dashboard/home')} />, index: true },
        {
          path: 'app',
          element: (
            <SearchContextProvider>
              <DashboardAppPage />
            </SearchContextProvider>
          ),
        },
        { path: 'newpage', element: <NewReport /> },
        { path: 'imprint', element: <Imprint /> },
        { path: 'report', element: <Report /> },
        { path: 'drinks', element: <DrinksPage /> },
        { path: 'home', element: <Home /> },
        
      ],
    },
    { path: 'tablet', element: <DrinksTablet /> },
    { path: 'kasse', element: <Kasse /> },
    { path: 'backup', element: <Backup /> },
    { path: 'upload', element: <Upload /> },
    {
      path:  (localStorage.getItem('deltaUsernameDigital') === "CC" ? '/tablet' : '/dashboard/home'),
      element: (
        <SearchContextProvider>
          <DashboardLayout />
        </SearchContextProvider>
      ),
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={ (localStorage.getItem('deltaUsernameDigital') === "CC" ? '/tablet' : '/dashboard/home')} />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
