import React, { createContext, useState,useEffect } from 'react';

export const SearchContext = createContext({
  searchTerm: '',
  setSearchTerm: () => {},
});

export const SearchContextProvider = (props) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    console.log('Search term changed: ', searchTerm);
  }, [searchTerm]);

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
      {props.children}
    </SearchContext.Provider>
  );
};
