import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import AccountPopover from '../header/AccountPopover';
import NotificationsPopover from '../header/NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);

  
  async function getData(url, token) {
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` ,
      'Content-Type': 'application/json',}
    });
    const data = await response.json();
    return data;
  }
  const test = async() => {
    const token = localStorage.getItem('deltatoken');
    const responseInfo = await getData('https://ralle.space/api/get_info', token);
  
    console.log("response")
    console.log(responseInfo)
    if(responseInfo.organizationname !== "Corps Delta"){
      //  window.location.href = '/login';
    }
    setUser(responseInfo.username);
    setEmail(responseInfo.organizationname);
  }

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    
  test();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
          <p style={{display: "none"}}>test</p>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5, marginTop: "20px" }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={"a"} alt={user}/>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 15 }}>
                {email}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>
     
     

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/assets/wappen.png"
            sx={{ width: 150, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center', opacity: 0 }}>
            <Typography  variant="h6">
              14-Tage Testversion
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary', display: 'none' }}>
              Danach 9€ / Monat
            </Typography>
          </Box>

          <Button href="" target="_blank" variant="contained" style={{ opacity: 0}}>
            Upgrade to Pro
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH }
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          style={{border: '10px solid black'}}

          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: '#ebeef1',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
