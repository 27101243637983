import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, Button, IconButton, InputAdornment, TextField, Typography, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [usname, setUsername] = useState( localStorage.getItem('deltaUsername') || '');
  const [pswd, setPassword] = useState( localStorage.getItem('deltaPswd') || '');
  const [isLoginError, setIsLoginError] = useState(false);
  const [resetMail, setResetMail] = useState("");
  const [successfullResetMail, setSuccesFullResetMail] = useState(false);


  async function fetchData(url, requestBody) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data;
  }
  async function getData(url, token) {
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` ,
      'Content-Type': 'application/json',}
    });
    const data = await response.json();
    return data;
  }

  function trim(input){
    const trimmed = input.trim();
    const words = trimmed.split(/\s+/);
    return words.join(' ')
  }
  const handleLogin = async () => {
    localStorage.setItem('deltaUsername', trim(usname));
    localStorage.setItem('deltaPswd', pswd);
    if(usname === " CC" || trim(usname) === "CC" || trim(usname) === "Kasse"){
      try {
        const credentials  = {
          username: trim(usname),
          password: pswd,
        };
  
        const base64EncodedCredentials = btoa(`${credentials.username}:${credentials.password}`);
        const response = await fetch('https://corpsdelta.study/api/login/', {
          method: 'POST',
          headers: {
            'Authorization': `Basic ${base64EncodedCredentials}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(credentials),
        });
        console.log(response)

        const data = await response.json();
        if (!response.ok){
          setIsLoginError(true);
          return 0;
        }
        const token = data.access_token;
        setIsLoginError(!token);
        if (token){
          localStorage.setItem('deltatoken', token);
          localStorage.setItem('deltaUsernameDigital', trim(usname));
          if(trim(usname) === "Kasse"){
            window.location.href =  "/kasse";
          }else{
            window.location.href =  "/tablet";
          }
        }
      }
      
      catch (error) {
        console.error(error);
      }
    }
    
    try {
      const response = await fetch('https://paukkeller.de/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: trim(usname),
        password : pswd,
        is_user: true}),
    });
    const data = await response.json();
    const token = data.access_token;

    if (!response.ok){
      setIsLoginError(true);
      return 0;
    }

      const responseInfo = await getData('https://paukkeller.de/api/get_info', token);
      console.log(responseInfo)
      if (responseInfo.organizationname === "Corps Delta"){
        setIsLoginError(!token);
        if (token){
          localStorage.setItem('deltatoken', token);
          localStorage.setItem('deltaUsernameDigital', trim(usname));
          window.location.href = "/dashboard";
        }
      }else{
        setIsLoginError(true);
      }
    } catch (error) {
      console.error(error);
    }
    return 0;
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  }; 

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  const forgotPswd = async() => {

    const response = await fetch('https://paukkeller.de/api/generate_reset_mail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email: trim(usname), is_user: true}),
    });
    const data = await response.json();

    if (response.ok){
      setSuccesFullResetMail(true);
    }
    
    console.log(data.message)
    setResetMail(data.message)
    
  };
  
  
  return (
    <>
      <Stack spacing={3} 
          style={{color: '#eee'}}>
      <TextField name="username"  label="E-Mail" value={usname} onChange={handleUsernameChange} 
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleLogin();
          }
        }} 
      />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={pswd}
          onChange={handlePasswordChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleLogin();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
         {resetMail && (<Typography color="#000"  style={{textAlign: "center"}}>{resetMail}</Typography>)}
         {isLoginError && (
          <Typography color="primary" style={{textAlign: "center"}}>Falsche Mail oder Passwort.</Typography>
        )}
        
      </Stack>
      
      <LoadingButton fullWidth size="large" type="submit" variant="contained" style={{marginTop: '10px'}} onClick={handleLogin}>
        Login
      </LoadingButton>
      {!successfullResetMail && (
      <Button onClick={forgotPswd} style={{marginTop: "10px"}} fullWidth>Forgot Password</Button>)}
      </>
  );
}