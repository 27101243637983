import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Avatar } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const TriangleImage = styled('img')({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',  // Equilateral triangle points upwards
});
const TriangleContainer = styled('div')({
  position: "relative",
  width: "500px",
  zIndex: 1,
  height: "400px",
  marginLeft: "80px"
});
// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
    <div style={{background: 'rgb(230, 229, 225)', height: "100%"}}>
      <Helmet>
        <title> Login | Delta Studies </title>
      </Helmet>

      <StyledRoot>
        <Avatar alt="Logo" src="../../../assets/wappen.png" sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }} style={{background: '#fff'}}/>


        {mdUp && (
          
          <StyledSection>
            
            <TriangleContainer>
      <TriangleImage src={"/assets/delta-background.jpeg"} alt="Triangle" /></TriangleContainer>
          
          </StyledSection>
          
        )}

        <Container maxWidth="sm" style={{blur: '10px', zIndex: 10}}>
          {mdUp ? (
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                Delta Studies Login
              </Typography>
              <LoginForm />
            </StyledContent>
          ) : (
            <>
              <Typography variant="h4" sx={{ px: 5, pt: 10 }} gutterBottom  style={{textAlign: "center", width: "100%"}}>
                Delta Studies Login
              </Typography>
              <LoginForm />
            </>
          )}
        </Container>
      </StyledRoot>
    </div></>
  );
}
